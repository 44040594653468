import { apiUrlMain, apiUrlProxy } from '@utils/constants';
import dynamic from 'next/dynamic';

export const routingManifest = {
  root: dynamic(() => import('@components/landing-pages/root-landing-page'), {
    ssr: false,
  }),
};

export const commonConfig = {
  apiUrlProxy: apiUrlProxy,
  apiUrlMain: apiUrlMain,
  logo: '/assets/images/archive-logo.svg',
  footerLogo: '/assets/images/archive-logo.svg',
  discordLogo: '/assets/images/discord1.svg',
  twitterLogo: '/assets/images/twitter1.svg',
};

export const customRoninConfig = {
  ...commonConfig,
  logo: '/assets/images/ronin-logo.svg',
  customStyle: {
    '--color-primary': '#2662d9',
    '--color-bg': '#20242D',
    '--color-text': 'white',
    '--color-dashboard-bg': '#111417',
    '--footer-text': 'white',
    '--font-primary':
      '-apple-system, BlinkMacSystemFont, Inter, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
    '--font-secondary':
      '-apple-system, BlinkMacSystemFont, Inter, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
    '--color-heading': 'white',
    '--breadcrumb-background': '#20242D',
    'font-feature-settings': '"tnum" on, "lnum" on, "ss01" on, "ss02" on, "ss04" on, "cv08" on, "case" on',
  },
};

export const protocolManifest = {
  balancer: () => import('./protocols/balancer'),
  uniswap: () => import('./protocols/uniswap'),
  aave: () => import('./protocols/aave'),
  aave_v2: () => import('./protocols/aave_v2'),
  matic_staking_v1_eth: () => import('./protocols/matic_staking_v1_eth'),
  uniswap_v2_eth: () => import('./protocols/uniswap_v2_eth'),
  sushiswap_eth: () => import('./protocols/sushiswap_eth'),
  lido_eth: () => import('./protocols/lido_eth'),
  sushiswap_farming_v1_eth: () => import('./protocols/sushiswap_farming_v1_eth'),
  ethereum_staking_eth: () => import('./protocols/ethereum_staking_eth'),
  compound: () => import('./protocols/compound'),
  pancakeswap_v2_bsc: () => import('./protocols/pancakeswap_v2_bsc'),
  pancakeswap_v3_bsc: () => import('./protocols/pancakeswap_v3_bsc'),
  gamma_finance_polygon: () => import('./protocols/gamma_finance_polygon'),
  verse_eth: () => import('./protocols/verse_eth'),
  trisolaris_aurora: () => import('./protocols/trisolaris_aurora'),
  across_eth: () => import('./protocols/across_eth'),
  evmos_staking: () => import('./protocols/evmos_staking'),
  cosmoshub_staking: () => import('./protocols/cosmoshub_staking'),
  spacefi_evmos: () => import('./protocols/spacefi_evmos'),
  ichi: () => import('./protocols/ichi'),
  clipper: () => import('./protocols/clipper'),
  bril_pancakeswap_bsc: () => import('./protocols/bril_pancakeswap_bsc'),
  retro_ichi_polygon: () => import('./protocols/retro_ichi_polygon'),
  orca_whirlpools_solana: () => import('./protocols/orca_whirlpools_solana'),
  avalanche_staking: () => import('./protocols/avalanche_staking'),
  balancer_v2_arbitrum: () => import('./protocols/balancer_v2_arbitrum'),
  maker_eth: () => import('./protocols/maker_eth'),
  rocketpool_eth: () => import('./protocols/rocketpool_eth'),
  katana_ronin: () => import('./protocols/katana_ronin'),
  rocketpool_income_eth: () => import('./protocols/rocketpool_income_eth'),
  quickswap_v3_polygon: () => import('./protocols/quickswap_v3_polygon'),
  bril_pancakeswap_eth: () => import('./protocols/bril_pancakeswap_eth'),
  morpho: () => import('./protocols/morpho'),
  aerodrome_v2_base: () => import('./protocols/aerodrome_v2_base'),
  aerodrome_v3_base: () => import('./protocols/aerodrome_v3_base'),
  spark: () => import('./protocols/spark'),
};
