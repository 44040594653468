import { useEffect, useRef, useState } from 'react';

const CustomTooltip = ({ customStyle, content, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);
  const containerRef = useRef(null);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    if (showTooltip && tooltipRef.current && containerRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let top, left;

      // Check if there's room below
      if (containerRect.bottom + tooltipRect.height <= viewportHeight) {
        top = containerRect.bottom;
      }
      // Check if there's room above
      else if (containerRect.top - tooltipRect.height >= 0) {
        top = containerRect.top - tooltipRect.height;
      }
      // If no room above or below, position at the side
      else {
        top = Math.max(0, (containerRect.top + containerRect.bottom) / 2 - tooltipRect.height / 2);
      }

      // Check if there's room to the right
      if (containerRect.right + tooltipRect.width <= viewportWidth) {
        left = containerRect.right;
      }
      // Check if there's room to the left
      else if (containerRect.left - tooltipRect.width >= 0) {
        left = containerRect.left - tooltipRect.width;
      }
      // If no room to left or right, center horizontally
      else {
        left = Math.max(0, (containerRect.left + containerRect.right) / 2 - tooltipRect.width / 2);
      }

      setTooltipPosition({ top, left });
    }
  }, [showTooltip]);

  return (
    <div className="custom-tooltip" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={containerRef}>
      {children}
      {showTooltip && (
        <div
          ref={tooltipRef}
          style={{
            ...customStyle,
            position: 'fixed',
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            maxWidth: '300px',
            zIndex: 9999,
          }}
          className="tooltip-content"
        >
          <div className="tooltip-content-inner">{content}</div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
