import axios from 'axios';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

const BAD_IMAGES = {};

export default function TokenLogo({ chainId, address, header = false, size, tokenImage, ...rest }) {
  const [storedTokenImage, setTokenImage] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const tokenResp = await axios.get(
        `${process.env.NEXT_CONTRACT_SERVER_URL}/tokenInfo/iconUrl/${chainId}/${address}`,
      );
      setTokenImage(tokenResp.data.result.iconUrl);
    };
    fetchData();
  }, []);

  return (
    <div style={{ display: 'flex', width: size, height: size, marginRight: 2 }} className="inline styled-img">
      {storedTokenImage && (
        <Image
          priority={true}
          width={size}
          height={size}
          alt={''}
          src={storedTokenImage}
          onError={(event) => {
            BAD_IMAGES[address] = true;
            event.preventDefault();
          }}
        />
      )}
    </div>
  );
}
